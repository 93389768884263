import React from 'react'
import LayoutGlobal from '../components/LayoutGlobal'
import Seo from '../components/Seo'
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import { FaFileAlt, FaFileVideo } from "react-icons/fa"

export default function OMnie() {
  return (
    
    <LayoutGlobal>

      <Seo
        title="O mnie"
        description="Łukasz Kaleńczuk - fizjoterapeuta i trener personalny. Prywatne Studio Medyczno-Treningowe w Warszawie. Pomagam opanować ból pleców, odzyskać optymalną sprawność oraz poprawić wygląd sylwetki."
        url="https://www.fizjopersonalny.pl/o-mnie"
      />

    <section>
      <div className='about_box about_margin_intro'>
        <div className='about_bio'>
          <span>Nazywam się</span>
          <h1>Łukasz Kaleńczuk</h1>
          <p>Jestem fizjoterapeutą z powołania oraz trenerem z zamiłowania. Prowadzę <b>prywatne Studio Medyczno-Treningowe</b>, gdzie pomagam zwiększyć komfort życia. Kilkunastoletnie doświadczenie pozwala mi wspierać moich klientów na wielu płaszczyznach: <b>pomoc w likwidacji bólu</b> (identyfikacja źródła bólu, zrozumienie mechanizmu działania bólu, wpływ codziennych czynników na intensywność bólu), <b>odzyskanie optymalnej sprawności</b> (rozpoczęcie systematycznego treningu, powrót do formy po ciąży lub kontuzji), <b>poprawa wyglądu sylwetki</b> (lepsza postawa ciała, zwiększenie masy mięśniowej, ujędrnienie kluczowych obszarów ciała, zrzucenie nadmiarowych kilogramów).</p><br />
          <p>Zobacz, co jeszcze mogę dla Ciebie zrobić.</p>
        </div>
        <div className='about_image'>
          <StaticImage src="../images/o-mnie.png" alt="Łukasz Kaleńczuk" />
        </div>
      </div>
    </section>

    <div className='about_menu'>
      <Link to="#kwalifikacje">kwalifikacje</Link>
      <Link to="#szkolenia">szkolenia i warsztaty</Link>
      <Link to="#media">media i artykuły gościnne</Link>
      <Link to="#hobby">w czasie wolnym</Link>
    </div>

    <section id="kwalifikacje">
      <div className='about_box about_margin_left'>
        <div className='about_img_left'>
          <StaticImage src="../images/kwalifikacje.jpg" alt="Łukasz Kaleńczuk" />
        </div>
        <div className='about_content'>
          <h2>Kwalifikacje</h2>
          <p>Posiadam wykształcenie z zakresu fizjoterapii i treningu na poziomie akademickim (Uniwersytecie Medycznym we Wrocławiu - studia magisterskie, Akademia Wychowania Fizycznego w Warszawie - studia podyplomowe).</p><br />
          <p>Pozyskuję wiedzę również na specjalistycznych kursach (stacjonarnych, online) w Polsce i za granicą. Zawodowe doświadczenie zdobywałem we współpracy z podmiotami państwowymi (szpitale, kliniki, instytuty naukowe) oraz z podmiotami prywatnymi (m.in. Carolina Medical Center, Rehasport Clinic).</p><br />
          <p>Identyfikuję się prawem wykonywania zawodu fizjoterapeuty o numerze 26314. Pełny wykaz kwalifikacji zawodowych znajdziesz <Link to="../kwalifikacje" title="Poznaj moje kwalifikacje" className='page_link'>TUTAJ</Link>.</p>
        </div>
      </div>
    </section>

    <section className='about_wyksztalcenie'>
      <div className='about_wyksztalcenie_col'>
        <div>
          <StaticImage src="../images/logo/kif.png" alt="Krajowa Izba Fizjoterapeutów" />
        </div>
        <div>
          <StaticImage src="../images/logo/ans.jpeg" alt="Akademia Nauk Stosowanych w Pile" />
        </div>
        <div>
          <StaticImage src="../images/logo/umed.jpeg" alt="Uniwersytet Medyczny we Wrocławiu" />
        </div>
      </div>

      <div className='about_wyksztalcenie_col'>
        <div>
          <StaticImage src="../images/logo/awf-wroclaw.jpg" alt="Akademia Wychowania Fizycznego we Wrocławiu" />
        </div>
        <div>
          <StaticImage src="../images/logo/awf-warszawa.jpg" alt="Akademia Wychowania Fizycznego w Warszawie" />
        </div>
        <div>
          <StaticImage src="../images/logo/swps.png" alt="Uniwersytet SWPS we Wrocławiu" />
        </div>
      </div>

      <div className='about_wyksztalcenie_col'>
        <div>
          <StaticImage src="../images/logo/instytut.png" alt="Instytut Sportu w Warszawie" />
        </div>
        <div>
          <StaticImage src="../images/logo/rehasport.jpg" alt="RehaSport Clinic w Poznaniu" />
        </div>
        <div>
          <StaticImage src="../images/logo/carolina.png" alt="Carolina Medical Center w Warszawie" />
        </div>
      </div>
    </section>

    <section className='about_bar'>
      <div className='about_bar_text'>Szukasz fizjoterapeuty i trenera, który pomoże Ci zwiększyć sprawność?</div>
      <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
    </section>

    <section id="szkolenia">
    <div className='about_box about_margin_right'>
      <div className='about_content'>
        <h2>Szkolenia i warsztaty</h2>
        <p>Z satysfakcją dzielę się wiedzą na szkoleniach i warsztatach. Wspieram firmy w procesie podnoszenia poziomu świadomości zdrowotnej pracowników. Nauczam studentów oraz uczestników kursów specjalistycznych.</p><br />
        <p>Prowadziłem szkolenia (także w języku angielskim) dla takich firm jak: Fit-Academy, Health Factory, Expondo, EDAG Engineering Polska czy ZAMST Polska.</p><br />
        <p>Moje wystąpienia zawsze przygotowuję w oparciu o szczegółową analiza potrzeb grupy docelowej, aby pomóc rozwiązać konkretne problemy.</p>
      </div>
      <div className='about_img_right'>
        <StaticImage src="../images/szkolenia.jpg" alt="Łukasz Kaleńczuk" />
      </div>
    </div>
    </section>

    <section className='about_projects'>
      <span>Aktywna promocja zdrowego stylu życia</span>
      <h3>Wybrane współprace</h3>
      <div className='project_row'>
        <div className='project_col'>
          <StaticImage src="../images/logo/projects/edag.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/zamst.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/expondo.png" alt="Krajowa Izba Fizjoterapeutów" />
        </div>
        <div className='project_col'>
          <StaticImage src="../images/logo/projects/fit-academy.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/wosp.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/health-factory.png" alt="Krajowa Izba Fizjoterapeutów" />
        </div>
      </div>
      <div className='project_row'>
        <div className='project_col'>
          <StaticImage src="../images/logo/projects/skn-mfir.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/skn-moveit.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/wsr.png" alt="Krajowa Izba Fizjoterapeutów" />
        </div>
        <div className='project_col'>
          <StaticImage src="../images/logo/projects/pzto.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/ifmsa.png" alt="Krajowa Izba Fizjoterapeutów" />
          <StaticImage src="../images/logo/projects/balet.png" alt="Krajowa Izba Fizjoterapeutów" />
        </div>
      </div>
    </section>

    <section className='about_bar'>
      <div className='about_bar_text'>Chcesz zorganizować szkolenie w swojej firmie?</div>
      <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
    </section>

    <section id="media">
      <div className='about_box about_margin_left'>
        <div className='about_img_left'>
          <StaticImage src="../images/media.jpg" alt="Łukasz Kaleńczuk" />
        </div>
        <div className='about_content'>
          <h2>Media i artykuły gościnne</h2>
          <p>Występuję w mediach ogólnopolskich, lokalnych oraz społecznościowych, gdzie dzielę się wiedzą ekspercką. Publikuję gościnne artykuły na tematy związane ze zdrową aktywnością fizyczną oraz prewencją przeciążeń mięśni i stawów. Poniżej znajdziesz niektóre publikacje i wystąpienia.</p><br />
          <ul className='media_items'>
            <li><FaFileAlt />
            <a href="https://pasja.azs.pl/plonace-sciegno-achillesa-co-robic-gdy-boli/" target="_blank" rel="noreferrer">Płonące ścięgno Achillesa - co robić, gdy boli?</a>
            </li>
            <li><FaFileAlt />
            <a href="https://eng.pl/blog/wywiad-z-fizjoterapeuta/" target="_blank" rel="noreferrer">Jaki wpływ na zdrowie ma dobór odpowiedniego obuwia?</a>
            </li>
            <li><FaFileVideo />
            <a href="https://youtu.be/55zaOdFuSc4" target="_blank" rel="noreferrer">Jak rehabilitować urazy i kontuzje?</a>
            </li>
            <li><FaFileVideo />
            <a href="https://youtu.be/P4IfY0_aupo" target="_blank" rel="noreferrer">Fizjoterapia vs Balet</a>
            </li>
            <li><FaFileVideo />
            <a href="https://youtu.be/fl2CyoITfII" target="_blank" rel="noreferrer">Koszykarz na siłowni (staw skokowy)</a>
            </li>
            <li><FaFileVideo />
            <a href="https://youtu.be/iPYSp0jloec" target="_blank" rel="noreferrer">Koszykarz na siłowni (zdrowe kolana)</a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className='about_bar'>
      <div className='about_bar_text'>Masz pomysł na wspólne promowanie zdrowia?</div>
      <a href="mailto:lukasz@fizjopersonalny.pl" target="_blank" rel="noreferrer" className='button'>Napisz do mnie</a>
    </section>

    <section id="hobby">
      <div className='about_box about_margin_right'>
        <div className='about_content'>
          <h2>W wolnym czasie</h2>
          <p>Oddaję się mojej pasji, jaką jest muzyka elektroniczna i to w róznych jej przejawach. Amatorsko zajmuję się produkcją muzyki (komponuję "do szuflady", ale jak zapytasz to może odpalę Ci moje kawałki :P). Uwielbiam uczestniczyć i dzielić się radością z przeżywania muzycznych uniesień na festiwalach i eventach zamkniętych.</p><br />
          <p>Wciągają mnie również książki, podcasty i kanały historyczne, szczególnie te poruszające okres XX wieku. Lubię dyskutować, spierać się i poznawać zdanie innych ludzi.</p><br />
          <p>Podróżuję w różne zakątki świata, ale najwięcej przyjemności daje mi odkrywanie rodzimych terenów i kontakt z naturą. Posiadam charakterną kotkę o imieniu Brandy.</p>
        </div>
        <div className='about_img_right'>
          <StaticImage src="../images/hobby.jpg" alt="Łukasz Kaleńczuk" />
        </div>
      </div>
    </section>

    </LayoutGlobal>
  )
}